import React, { useState, useContext } from 'react';
import Rodal from 'rodal';
import { UALContext } from 'ual-reactjs-renderer';
import { toast } from 'react-toastify';
import { toastOptions } from '../../../helpers/toast.helper';
import {
  getBalance,
  getStakedTokens,
  stake,
  unstake,
} from '../../../services/wax.services';
import OrangeBorderButton from '../../OrangeBorderButton/OrangeBorderButton';
import { useDispatch, useSelector } from 'react-redux';
import './RodalStakeUnstakePool.scss';

function RodalStakeUnstakePool({ visible, hide, content }) {
  const dispatch = useDispatch();
  const { activeUser } = useContext(UALContext);
  const alchBalance = useSelector((state) => state.alchBalance);
  const stakedTokens = useSelector((state) => state.stakedTokens);

  const [amount, setAmount] = useState(0);

  const handleAction = () => {
    if (content === 'stake') {
      handleStake();
    } else {
      handleUnstake();
    }
  };

  const handleStakeUnstake = (actionFn, successMessage) => {
    actionFn({ activeUser, amount })
      .then(() => {
        setAmount(0);
        toast.success(successMessage, toastOptions);

        setTimeout(() => {
          getBalance({ user: activeUser?.accountName })
            .then((data) => {
              dispatch({ type: 'ALCH_BALANCE', payload: data });
            })
            .catch((error) => {
              console.log(error);
            });
        }, 1500);

        setTimeout(() => {
          getStakedTokens({ activeUser })
            .then((data) => {
              dispatch({ type: 'STAKED_TOKENS', payload: data });
            })
            .catch((error) => {
              console.log(error);
            });
        }, 1500);
      })
      .catch((error) => {
        setAmount(0);
        const errorMessage = error.message.replace(
          'assertion failure with message: ',
          ''
        );
        toast.error(errorMessage, toastOptions);
        console.log(error);
      });
  };

  const handleStake = () => {
    handleStakeUnstake(stake, 'Staked');
  };

  const handleUnstake = () => {
    handleStakeUnstake(unstake, 'Unstaked');
  };

  return (
    <Rodal
      className="rodal-stake"
      visible={visible}
      onClose={hide}
      closeMaskOnClick={false}
    >
      <h3>
        Choose the amount of ALCH you want to{' '}
        {content === 'stake' ? 'stake' : 'unstake'}
      </h3>
      <div className="rodal-stake-content">
        <p>
          {content === 'stake' ? (
            <div onClick={() => setAmount(alchBalance.split(' ')[0])}>
              Your ALCH Balance: <span>{alchBalance}</span>
            </div>
          ) : (
            <>
              Your staked ALCH Balance:{' '}
              <span
                onClick={() =>
                  setAmount(stakedTokens[0]?.staked_tokens.split(' ')[0])
                }
              >
                {stakedTokens[0]?.staked_tokens}
              </span>
            </>
          )}
        </p>
        <div className="rodal-stake-content_field">
          <input
            type="text"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <OrangeBorderButton onClick={handleAction}>
            {content === 'stake' ? 'Stake' : 'Unstake'}
          </OrangeBorderButton>
        </div>
        {content === 'stake' && (
          <span>
            Your staked ALCH Balance: {stakedTokens[0]?.staked_tokens}
          </span>
        )}
      </div>
    </Rodal>
  );
}

export default RodalStakeUnstakePool;
