import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export function ExponentialDiagramForTrade({
  startPrice,
  delta,
  amountToBuy,
  amountToSell,
  fee,
  setWillCost,
  setWillEarn,
}) {
  const [dataDiagram, setDataDiagram] = useState([]);
  const [labels, setLabels] = useState([]);

  function ExponentialCurveForTrade() {
    const sellArr = [];
    const buyArr = [];
    const increaseRate = 1 + delta / 100;
    let price = Number(startPrice);
    for (let i = 1; i <= Number(amountToSell); i++) {
      sellArr.push(price);
      price *= increaseRate;
    }
    price = startPrice;

    for (let i = 0; i <= Number(amountToBuy); i++) {
      const priceWithFee = price * (1 - Number(fee) / 100);
      buyArr.push(priceWithFee);
      price /= increaseRate;
    }
    buyArr.reverse().pop();

    setWillCost(buyArr);
    setWillEarn(sellArr);
    setDataDiagram([...buyArr, ...sellArr]);
  }

  useEffect(() => {
    ExponentialCurveForTrade();
  }, [startPrice, delta, amountToBuy, amountToSell, fee]);

  useEffect(() => {
    const labels = [];

    for (let i = amountToBuy; i >= 1; i--) {
      labels.push(i.toString());
    }

    for (let i = 1; i <= amountToSell; i++) {
      labels.push(i.toString());
    }

    setLabels(labels);
  }, [amountToSell, amountToBuy]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: null,
      },
    },
    scales: {
      y: {
        ticks: { color: '#FFFFFF', beginAtZero: true },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: '#8E8E8E',
        },
      },
      x: {
        ticks: { color: '#FFFFFF', beginAtZero: true },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: '#2B282C',
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: dataDiagram,
        borderColor: 'rgb(255,255,255)',
        backgroundColor: 'rgba(77,50,42,0.54)',
        pointBorderWidth: 7,
        // pointBorderColor: generatePointBackgroundColors,
        pointBackgroundColor: '#E65634',
        fill: true,
        color: '#FFFFFF',
      },
    ],
  };

  return <Line width={927} height={241} options={options} data={data} />;
}
