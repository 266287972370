import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './FilteredTable.scss';

const FilteredTable = ({ allCollections, allPools }) => {
  const [sortKey, setSortKey] = useState(null);
  const [sortOrders, setSortOrders] = useState({
    name: 'asc',
    pools: 'asc',
    sales: 'asc',
    volume: 'asc',
  });

  // const handleLoadMore = () => {
  //     setDisplayedCount(displayedCount + 50);
  // };

  const handleSort = (key) => {
    if (sortKey === key) {
      // If the same key is clicked again, toggle the sort order
      setSortOrders((prevSortOrders) => ({
        ...prevSortOrders,
        [key]: prevSortOrders[key] === 'asc' ? 'desc' : 'asc',
      }));
    } else {
      // If a new key is clicked, set it as the sort key and default to ascending order
      setSortKey(key);
      setSortOrders((prevSortOrders) => ({
        ...prevSortOrders,
        [key]: 'asc',
      }));
    }
  };

  const sortedCollections = [...allCollections].sort((a, b) => {
    // Custom sorting function based on the sortKey and sortOrder
    if (sortKey === 'name') {
      if (a.collection_name < b.collection_name)
        return sortOrders.name === 'asc' ? -1 : 1;
      if (a.collection_name > b.collection_name)
        return sortOrders.name === 'asc' ? 1 : -1;
    } else if (sortKey === 'pools') {
      return sortOrders.pools === 'asc'
        ? a.poolCount - b.poolCount
        : b.poolCount - a.poolCount;
    } else if (sortKey === 'sales') {
      return sortOrders.sales === 'asc' ? a.sales - b.sales : b.sales - a.sales;
    } else if (sortKey === 'volume') {
      return sortOrders.volume === 'asc'
        ? a.volume - b.volume
        : b.volume - a.volume;
    }
    return 0;
  });

  const filteredCollections = sortedCollections
    .map((item) => {
      const collectionWithPools = allPools.filter(
        (pool) =>
          pool?.NFTs_collection?.collection_name === item?.collection_name
      );
      let poolCount = collectionWithPools.length;

      collectionWithPools.forEach((pool) => {
        if (Array.isArray(pool.pool_nfts) && pool.pool_nfts.length === 0) {
          poolCount -= 1;
        }

        if (parseFloat(pool.pool_asset) < parseFloat(pool.floor_price)) {
          poolCount -= 1;
        }
      });

      return {
        ...item,
        poolCount: poolCount,
      };
    })
    .filter((item) => item.poolCount > 0);

  // const filteredCollections = sortedCollections
  //     .map((item) => {
  //         const collectionWithPools = allPools.filter((pool) => pool.NFTs_collection?.collection_name === item.collection_name);
  //         const poolCount = collectionWithPools.length;
  //
  //         return {
  //             ...item,
  //             poolCount: poolCount,
  //         };
  //     })
  //     .filter((item) => item.poolCount > 0);

  return (
    <div className="filter-table">
      <div className="filter">
        <div className="name" onClick={() => handleSort('name')}>
          <p>Name</p>
          <div className="arrows">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="6"
              viewBox="0 0 12 6"
            >
              <path
                id="Polygon_4"
                data-name="Polygon 4"
                d="M5.646.354a.5.5,0,0,1,.707,0l4.793,4.793A.5.5,0,0,1,10.793,6H1.207a.5.5,0,0,1-.354-.854Z"
                fill={
                  sortKey === 'name' && sortOrders.name === 'asc'
                    ? '#fff'
                    : '#8e8e8e'
                }
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="6"
              viewBox="0 0 12 6"
            >
              <path
                id="Polygon_5"
                data-name="Polygon 5"
                d="M5.646.354a.5.5,0,0,1,.707,0l4.793,4.793A.5.5,0,0,1,10.793,6H1.207a.5.5,0,0,1-.354-.854Z"
                transform="translate(12 6) rotate(180)"
                fill={
                  sortKey === 'name' && sortOrders.name === 'desc'
                    ? '#fff'
                    : '#8e8e8e'
                }
              />
            </svg>
          </div>
        </div>
        <div className="pools" onClick={() => handleSort('pools')}>
          <p>Pools</p>
          <div className="arrows">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="6"
              viewBox="0 0 12 6"
            >
              <path
                id="Polygon_4"
                data-name="Polygon 4"
                d="M5.646.354a.5.5,0,0,1,.707,0l4.793,4.793A.5.5,0,0,1,10.793,6H1.207a.5.5,0,0,1-.354-.854Z"
                fill={
                  sortKey === 'pools' && sortOrders.pools === 'asc'
                    ? '#fff'
                    : '#8e8e8e'
                }
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="6"
              viewBox="0 0 12 6"
            >
              <path
                id="Polygon_5"
                data-name="Polygon 5"
                d="M5.646.354a.5.5,0,0,1,.707,0l4.793,4.793A.5.5,0,0,1,10.793,6H1.207a.5.5,0,0,1-.354-.854Z"
                transform="translate(12 6) rotate(180)"
                fill={
                  sortKey === 'pools' && sortOrders.pools === 'desc'
                    ? '#fff'
                    : '#8e8e8e'
                }
              />
            </svg>
          </div>
        </div>
        <div className="floor" onClick={() => handleSort('sales')}>
          <p>Sales</p>
          <div className="arrows">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="6"
              viewBox="0 0 12 6"
            >
              <path
                id="Polygon_4"
                data-name="Polygon 4"
                d="M5.646.354a.5.5,0,0,1,.707,0l4.793,4.793A.5.5,0,0,1,10.793,6H1.207a.5.5,0,0,1-.354-.854Z"
                fill={
                  sortKey === 'sales' && sortOrders.sales === 'desc'
                    ? '#fff'
                    : '#8e8e8e'
                }
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="6"
              viewBox="0 0 12 6"
            >
              <path
                id="Polygon_5"
                data-name="Polygon 5"
                d="M5.646.354a.5.5,0,0,1,.707,0l4.793,4.793A.5.5,0,0,1,10.793,6H1.207a.5.5,0,0,1-.354-.854Z"
                transform="translate(12 6) rotate(180)"
                fill={
                  sortKey === 'sales' && sortOrders.sales === 'asc'
                    ? '#fff'
                    : '#8e8e8e'
                }
              />
            </svg>
          </div>
        </div>
        <div className="volume" onClick={() => handleSort('volume')}>
          <p>Volume</p>
          <div className="arrows">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="6"
              viewBox="0 0 12 6"
            >
              <path
                id="Polygon_4"
                data-name="Polygon 4"
                d="M5.646.354a.5.5,0,0,1,.707,0l4.793,4.793A.5.5,0,0,1,10.793,6H1.207a.5.5,0,0,1-.354-.854Z"
                fill={
                  sortKey === 'volume' && sortOrders.volume === 'desc'
                    ? '#fff'
                    : '#8e8e8e'
                }
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="6"
              viewBox="0 0 12 6"
            >
              <path
                id="Polygon_5"
                data-name="Polygon 5"
                d="M5.646.354a.5.5,0,0,1,.707,0l4.793,4.793A.5.5,0,0,1,10.793,6H1.207a.5.5,0,0,1-.354-.854Z"
                transform="translate(12 6) rotate(180)"
                fill={
                  sortKey === 'volume' && sortOrders.volume === 'asc'
                    ? '#fff'
                    : '#8e8e8e'
                }
              />
            </svg>
          </div>
        </div>
      </div>
      {filteredCollections.map((item, index) => (
        <Link key={index} to={item.collection_name}>
          <div className="filter-table_item">
            <div className="name">
              <div className="avatar">
                {item.data.img && (
                  <img
                    src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`}
                    alt=""
                  />
                )}
              </div>
              <p>{item.collection_name}</p>
            </div>
            <div className="pools">
              <p>{item.poolCount}</p>
            </div>
            <div className="floor">
              <p>{item.sales}</p>
            </div>
            <div className="volume">
              <p>{(item.volume / 100000000).toFixed(0)} WAX</p>
            </div>
          </div>
        </Link>
      ))}

      {/*<div className="load-more">*/}
      {/*    <OrangeBorderButton onClick={handleLoadMore}>Load more</OrangeBorderButton>*/}
      {/*</div>*/}
    </div>
  );
};

export default FilteredTable;
