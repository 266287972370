import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UALContext } from 'ual-reactjs-renderer';

import OrangeBorderButton from '../OrangeBorderButton/OrangeBorderButton';
import OrangeButton from '../OrangeButton/OrangeButton';

import Logo from '../../images/logo.png';
import TokenLogo from '../../images/logo@2x.png';
import LogOut from '../../images/logout.png';
import WaxIcon from '../../images/wax_logo.png';

import './Header.scss';

import { getCollectionsSearch } from '../../services/wax.services';
// import {WalletIconSVG} from "../../images/SVGElements/WalletIcon";
import { TableIconSVG } from '../../images/SVGElements/TableIcon';
import { CoinsIconSVG } from '../../images/SVGElements/CoinsIcon';
import { StarIconSVG } from '../../images/SVGElements/StarIcon';
// import {StatsIconSVG} from "../../images/SVGElements/StatsIcon";
import { LogOutIconSVG } from '../../images/SVGElements/LogOutIcon';

function Header() {
  const { activeUser, showModal, logout } = useContext(UALContext);
  const alchBalance = useSelector((state) => state.alchBalance);
  const waxBalance = useSelector((state) => state.waxBalance);

  const [searchText, setSearchText] = useState('');
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [burgerOpen, setBurgerOpen] = useState(false);
  const [burgerDesctopOpen, setBurgerDesctopOpen] = useState(false);

  const burgerMenuDesctopContentRef = useRef(null);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        burgerDesctopOpen &&
        burgerMenuDesctopContentRef.current &&
        !burgerMenuDesctopContentRef.current.contains(event.target)
      ) {
        setBurgerDesctopOpen(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [burgerDesctopOpen]);

  useEffect(() => {
    if (searchText.length > 3) {
      getCollectionsSearch(searchText)
        .then((data) => {
          setFilteredCollections(data);
        })
        .catch((e) => console.log(e));
    }
  }, [searchText]);

  const handleBurgerDesctopClick = () => {
    setBurgerDesctopOpen(!burgerDesctopOpen);
  };

  const handleConnect = () => {
    showModal();
  };

  const handleLogOut = () => {
    logout();
    setBurgerDesctopOpen(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const openAncor = () => {
    window.open(
      'https://wax.alcor.exchange/trade/alch-alchemyswapy_wax-eosio.token',
      '_blank'
    );
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="header-left">
          <Link to={'/'}>
            <div className="header-left_logo">
              <img src={Logo} alt="" />
            </div>
          </Link>
          <div className="header-left_search">
            <input
              type="text"
              placeholder={'Search collections'}
              onChange={handleSearchInputChange}
              value={searchText}
            />
            {searchText.length > 2 && (
              <ul className="header-left_search-results">
                {filteredCollections.map((collection, index) => (
                  <Link to={`/collections/${collection.collection_name}`}>
                    <li onClick={() => setSearchText('')} key={index}>
                      {collection.collection_name}
                    </li>
                  </Link>
                ))}
              </ul>
            )}
            {/* <div className={'header-links'}>
                            <Link to={'/collections'}>
                                <p className={'item-header'}>Collections</p> 
                            </Link>
                            <Link to={'/pools'}>
                                <p className={'item-header'}>All pools</p> 
                            </Link>
                            <Link to={'/pool'}>
                                <p className={'item-header'}>My pools</p> 
                            </Link>
                            <p className={'item-header-not-allow'}>Staking</p> 
                        </div> */}
          </div>
        </div>
        <div className="header-right">
          <div className="header-right_logIn">
            {activeUser && (
              <div className={'token-block'} onClick={() => openAncor()}>
                <div className={'alch-token'}>
                  <div className="token-icon">
                    <img src={TokenLogo} alt="" />
                  </div>
                  <div className="token-amount">
                    <p>{alchBalance}</p>
                  </div>
                </div>
                <div className={'wax-token'}>
                  <div className="token-icon">
                    <img src={WaxIcon} alt="" />
                  </div>
                  <div className="token-amount">
                    <p>{parseFloat(waxBalance).toFixed(2)} WAX</p>
                  </div>
                </div>
              </div>
            )}
            {/*<OrangeBorderButton>Your NFTs</OrangeBorderButton>*/}
            {/* <OrangeBorderButton link={'/pool'}>My Pools</OrangeBorderButton> */}
            {!activeUser ? (
              <OrangeButton onClick={handleConnect}>Connect</OrangeButton>
            ) : (
              <div className={'user-block'}>
                <div className="avatar"></div>
                <div className="user-name">
                  <p>{activeUser.accountName}</p>
                </div>
                <div className="arrow"></div>

                <div
                  className={`burger + ${burgerDesctopOpen ? 'open' : ''}`}
                  onClick={handleBurgerDesctopClick}
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </div>

                {burgerDesctopOpen ? (
                  <div
                    className={`burger-menu-desctop-content ${
                      burgerDesctopOpen ? 'open' : ''
                    }`}
                    ref={burgerMenuDesctopContentRef}
                  >
                    <ul>
                      {/*<Link to={'/wallet'} onClick={() => setBurgerDesctopOpen(false)}>*/}
                      {/*    <li>*/}
                      {/*        <WalletIconSVG />*/}
                      {/*        <p>Wallet //TBD</p>*/}
                      {/*    </li>*/}
                      {/*</Link>*/}
                      <Link
                        to={'/pool'}
                        onClick={() => setBurgerDesctopOpen(false)}
                      >
                        <li>
                          <TableIconSVG />
                          <p>My Pools</p>
                        </li>
                      </Link>
                      <Link
                        to={'/pools'}
                        onClick={() => setBurgerDesctopOpen(false)}
                      >
                        <li>
                          <TableIconSVG />
                          <p>All Pools</p>
                        </li>
                      </Link>
                      <Link
                        to={'/staking'}
                        onClick={() => setBurgerDesctopOpen(false)}
                      >
                        <li>
                          <CoinsIconSVG />
                          <p>Staking</p>
                        </li>
                      </Link>
                      <Link
                        to={'/collections'}
                        onClick={() => setBurgerDesctopOpen(false)}
                      >
                        <li>
                          <StarIconSVG />
                          <p>Collections</p>
                        </li>
                      </Link>
                      {/*<Link to={'/statistics'} onClick={() => setBurgerDesctopOpen(false)}>*/}
                      {/*    <li>*/}
                      {/*        <StatsIconSVG />*/}
                      {/*        <p>Statistics //TBD</p>*/}
                      {/*    </li>*/}
                      {/*</Link>*/}
                      <li onClick={handleLogOut}>
                        <LogOutIconSVG />
                        <p>Log Out</p>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
            )}
          </div>
          {/*<div className="header-right_cart">*/}
          {/*    <img src={CartIcon} alt=""/>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="header-container-mobile">
        {burgerOpen ? (
          <div
            className="burger-menu"
            onClick={() => setBurgerOpen(!burgerOpen)}
          >
            <p> &lt; </p>
          </div>
        ) : (
          <div
            className="burger-menu"
            onClick={() => setBurgerOpen(!burgerOpen)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}

        <div className="header-left_logo">
          <Link to={'/'}>
            <img src={Logo} alt="" />
          </Link>
        </div>

        {!activeUser ? (
          <OrangeButton
            className={'connect-for-mobile'}
            onClick={handleConnect}
          >
            Connect
          </OrangeButton>
        ) : (
          <div className={'user-block'}>
            <div className="avatar"></div>
            <div className="user-name">
              <p>{activeUser.accountName}</p>
            </div>
            <div className="arrow"></div>
          </div>
        )}

        {burgerOpen ? (
          <div className={'burger-menu-content'}>
            <div className="burger-menu-content_wrapper">
              <OrangeBorderButton
                onClick={() => setBurgerOpen(false)}
                link={'/collections'}
                className={'burger-menu-content_wrapper-btn'}
                width={626}
                height={72}
              >
                Collections
              </OrangeBorderButton>
              <OrangeBorderButton
                onClick={() => setBurgerOpen(false)}
                link={'/pools'}
                className={'burger-menu-content_wrapper-btn'}
                width={626}
                height={72}
              >
                All Pools
              </OrangeBorderButton>
              <OrangeBorderButton
                onClick={() => setBurgerOpen(false)}
                link={'/pool'}
                className={'burger-menu-content_wrapper-btn'}
                width={626}
                height={72}
              >
                Your Pools
              </OrangeBorderButton>
              <OrangeBorderButton
                className={'burger-menu-content_wrapper-btn'}
                width={626}
                height={72}
                onClick={() => logout()}
              >
                <div className="logout-burger">
                  <img src={LogOut} alt="" />
                  <p>Logout</p>
                </div>
              </OrangeBorderButton>
            </div>
          </div>
        ) : (
          false
        )}
      </div>
    </header>
  );
}

export default Header;
